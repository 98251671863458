function addClass(el: HTMLElement, ...className: string[]) {
    if (!el) return;

    el.classList.add(...className)
}

function deleteClass(el: HTMLElement, ...className: string[]) {
    if (!el) return;
    el.classList.remove(...className)
}

function replaceClass(el: HTMLElement, srcClass: string, targetClass: string) {
    if (!el) return;

    if (srcClass)
        el.classList.remove(srcClass)
    
    if(targetClass)
        el.classList.add(targetClass)
}

function hasClass(el: HTMLElement, className: string): boolean {
    if (!el) return false;
    return el.classList.contains(className)
}

export { addClass, deleteClass, hasClass, replaceClass };